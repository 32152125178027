import React, { useState } from "react";
import ProfileData from "../ProfileData";
import { Swiper, SwiperSlide } from "swiper/react";
import {Autoplay } from 'swiper/modules';



import IMG0 from "../img/project/project-1.PNG";
import IMG1 from "../img/project/project-2.PNG";
import IMG2 from "../img/project/project-3.PNG";
import IMG3 from "../img/project/project-4.PNG";
import IMG4 from "../img/project/project-5.PNG";
import IMG01 from "../img/project/project-11.PNG";
import IMG11 from "../img/project/project-21.PNG";
import IMG21 from "../img/project/project-31.PNG";
import IMG31 from "../img/project/project-41.PNG";
import IMG41 from "../img/project/project-51.PNG";
import IMG02 from "../img/project/project-12.PNG";
import IMG12 from "../img/project/project-22.PNG";
import IMG22 from "../img/project/project-32.PNG";
import IMG32 from "../img/project/project-41.PNG";
import IMG42 from "../img/project/project-52.PNG";
import 'swiper/css';

function ProjectHero() {
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(null);
  const Images = [IMG0,IMG1,IMG2,IMG3,IMG4,IMG01,IMG11,IMG21,IMG31,IMG41,IMG02,IMG12,IMG22,IMG32, IMG42];
  const PrjIMG = [IMG0, IMG1, IMG2, IMG3, IMG4];
  const ProjectsDetails = ProfileData.ProjectsDetails;

  const handleClick = (index) => {
    setSelectedProjectIndex(index === selectedProjectIndex ? null : index);
  };

  return (
    <section id="portfolio" className="portfolio section-bg">
      <div className="container">
        <div className="section-title">
          <h2>Projects</h2>
        </div>

        <div
          className="row portfolio-container"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          {ProjectsDetails.Projects.map((Project, index) => (
            <div
              key={index}
              className="col-lg-4 col-md-6 portfolio-item filter-app "
              onClick={() => handleClick(index)}
            >
              <div className="portfolio-wrap cards">
                <img src={PrjIMG[index]} className="img-fluid" alt=""></img>
                <div className={"portfolio-links " + Project}>
                  <a href="#myDIV" className="ahrefid" title="More Details">
                    <h3 className="portfolio_h3">
                      {ProjectsDetails.PrjHeading[index]}
                    </h3>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/*Project details*/}

        <div id="myDIV" className="container" data-aos="fade-left">
          {selectedProjectIndex !== null && (
            <div className="section-title">
              <h2 id="headings">
                {ProjectsDetails.PrjHeading[selectedProjectIndex]}
              </h2>
            </div>
          )}

          <section
            id="portfolio-details"
            className="portfolio-details"
            style={{
              display: selectedProjectIndex !== null ? "block" : "none",
            }}
          >
            <div className="container">
              <div id="myDIV" className="container" data-aos="fade-left">
                <section className="portfolio-details">
                  <div className="row gy-4">
                    <div className="col-lg-8">
                      <Swiper
                        modules={[Autoplay]}
                        spaceBetween={50}
                        slidesPerView={1}
                        autoplay={{ delay: 4000 }}
                        >
                        {[0, 5, 10].map((i, el) => {
                          return (
                            <SwiperSlide>
                              <img
                                id="slide"
                                className="imgslide"
                                src={Images[selectedProjectIndex + i]}
                                alt={Images[selectedProjectIndex + i]}
                              ></img>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>

                    <div className="col-lg-4">
                      <div className="portfolio-info">
                        <h3 id="title">Project information</h3>
                        <ul>
                          <li>
                            <strong>Category</strong>
                            <p id="cat">
                              {ProjectsDetails.Category[selectedProjectIndex]}
                            </p>
                          </li>
                          <li>
                            <strong>Project date</strong>
                            <p id="date">
                              {ProjectsDetails.Date[selectedProjectIndex]}
                            </p>
                          </li>
                          <li>
                            <strong>Project URL</strong>:
                            <a
                              href={
                                "https://" +
                                ProjectsDetails.Url[selectedProjectIndex]
                              }
                              rel="noreferrer"
                              target="_blank"
                              id="url"
                            >
                              {ProjectsDetails.PrjHeading[selectedProjectIndex]}
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="portfolio-description">
                        <ul>
                          <li id="details">
                            {ProjectsDetails.Details[selectedProjectIndex]}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
}

export default ProjectHero;
