import React from "react";
import ProfileData from "../ProfileData";

const Resume = () => {
  let Resume = ProfileData.Resume;

  return (
    <section id="resume" className="resume">
      <div className="container">
        <div className="section-title">
          <h2>Resume</h2>
        </div>

        <div className="row">
          <h3 className="resume-title">{Resume.work.Title}</h3>
          {Resume.work.jobs.map((resume_item, index) => (
            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
              <div key={index} className="resume-item">
                {resume_item.SubTitle && (
                  <div>
                    <h4>{resume_item.Title}</h4>
                    <h5>{resume_item.TimeGap}</h5>
                    <p>
                      <em>
                        {resume_item.Name}, {resume_item.Location}
                      </em>
                    </p>
                  </div>
                )}
                {/* Check if Responsibilities exist */}
                {resume_item.Responsibilities && (
                  <>
                    <p>
                      <em>Responsibilities</em>
                    </p>
                    <ul>
                      {resume_item.Responsibilities.map(
                        (responsibility, index) => (
                          <li key={index}>{responsibility}</li>
                        )
                      )}
                    </ul>
                  </>
                )}
                {/* Check if Achievements exist */}

                {resume_item.Achievements && (
                  <>
                    <p>
                      <em>Achievements</em>
                    </p>
                    <ul>
                      {resume_item.Achievements.map((achievement, index) => (
                        <li key={index}>{achievement}</li>
                      ))}
                    </ul>
                  </>
                )}
                {/* Check if Description exist */}
                {resume_item.Description && (
                  <ul>
                    {resume_item.Description.map((Description, index) => (
                      <li key={index}>{Description}</li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          ))}

          <h3 className="resume-title">{Resume.Education.Title}</h3>
          {Resume.Education.details.map((resume_item, index) => (
            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
              <div key={index} className="resume-item">
                <h4>{resume_item.Title}</h4>
                <h5>{resume_item.TimeGap}</h5>
                <p>
                  <em>
                    {resume_item.Name}, {resume_item.Location}
                  </em>
                </p>
                {/* Check if Responsibilities exist */}
                {resume_item.Responsibilities && (
                  <ul>
                    {resume_item.Responsibilities.map(
                      (responsibility, index) => (
                        <li key={index}>{responsibility}</li>
                      )
                    )}
                  </ul>
                )}
                {/* Check if Achievements exist */}
                {resume_item.Achievements && (
                  <ul>
                    {resume_item.Achievements.map((achievement, index) => (
                      <li key={index}>{achievement}</li>
                    ))}
                  </ul>
                )}
                {/* Check if Description exist */}
                {resume_item.Description && (
                  <ul>
                    {resume_item.Description.map((Description, index) => (
                      <li key={index}>{Description}</li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Resume;
