import React, { useEffect, useRef } from "react";
import Typed from "typed.js";
import profileData from "../ProfileData.json";

const MainHero = () => {
  let data = profileData.MainHero;
  console.log("MainHero", data);

  const typingEffect = useRef(null);

  useEffect(() => {
    const typed = new Typed(typingEffect.current, {
      strings: data.Intro_Text, // Strings to display
      // Speed settings, try diffrent values untill you get good results
      startDelay: 200,
      typeSpeed: 100,
      backDelay: 100,
      backSpeed: 120,
      smartBackspace: true,
      loop: true,
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, [data.Intro_Text]);

  return (
    <section
      id="hero"
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <div className="hero-container">
        <h1>Nishant Arya&nbsp;&nbsp;&nbsp;</h1>
        {/* Element to display typing strings */}
        <p>
          <span ref={typingEffect}></span>
        </p>
      </div>
    </section>
  );
};

export default MainHero;
