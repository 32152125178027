import React, { useEffect } from "react";

function Contact() {
  useEffect(() => {
    // Load external script when component mounts
    const script = document.createElement("script");
    script.src = "https://static-bundles.visme.co/forms/vismeforms-embed.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <section
      id="contact"
      className="contact"
      data-aos="fade-up"
      data-aos-delay="100"
    >
      <div className="container">
        <div className="section-title">
          <h2>Contact</h2>
        </div>
        {/* Contact section code... */}
        <div>
          <div
            className="visme_d"
            data-title="Contact Me Contact Form"
            data-url="01qqepn9-contact-me-contact-form"
            data-domain="forms"
            data-full-page="false"
            data-min-height="400px"
            data-form-id="13389"
          ></div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
