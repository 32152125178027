import React from "react";
import WebDev from "../img/project/webtech.jpg";
import AI from "../img/project/Ai.jpg";
import Tech from "../img/project/tech.jpg";

const Skills = () => {
  return (
    <section id="skills" className="skills section-bg">
      <div className="container">
        <div className="section-title">
          <h2>Skills</h2>
        </div>
        {/*first half--*/}
        <div className="row skills-content">
          <div className="col-md-4" data-aos="fade-up" data-aos-delay="100">
            <div className="card user-card">
              <div className="card-block">
                <div className="user-image">
                  <img src={Tech} className="img-radius" alt="Technology"></img>
                </div>
                <h5 className="f-w-600 m-t-25 m-b-10">Technology</h5>
                <ul className="list-unstyled activity-leval">
                  <li className="active"></li>
                </ul>
                <hr></hr>
                <div className="bg-c-blue counter-block m-t-10 p-20">
                  <p>J2SE/J2EE</p>
                  <p>Struts</p>
                  <p>Spring</p>
                  <p>Swing</p>
                  <p>Hibernate</p>
                </div>
                <hr></hr>
              </div>
            </div>
          </div>

          <div className="col-md-4" data-aos="fade-up" data-aos-delay="100">
            <div className="card user-card">
              <div className="card-block">
                <div className="user-image">
                  <img src={WebDev} className="img-radius" alt="WebDev"></img>
                </div>
                <h5 className="f-w-600 m-t-25 m-b-10">Web development</h5>
                <ul className="list-unstyled activity-leval">
                  <li className="active"></li>
                </ul>
                <hr></hr>

                <div className="bg-c-blue counter-block m-t-10 p-20">
                  <p>React</p>
                  <p>Node.JS</p>
                  <p>JSP</p>
                  <p>Servlet</p>
                  <p>JavaScript</p>
                </div>
                <hr></hr>
              </div>
            </div>
          </div>

          <div className="col-md-4" data-aos="fade-up" data-aos-delay="100">
            <div className="card user-card">
              <div className="card-block">
                <div className="user-image">
                  <img src={AI} className="img-radius" alt="AI"></img>
                </div>
                <h5 className="f-w-600 m-t-25 m-b-10">
                  Data Science, ML & Analitics
                </h5>
                <ul className="list-unstyled activity-leval">
                  <li className="active"></li>
                </ul>
                <hr></hr>

                <div className="bg-c-blue counter-block m-t-10 p-20">
                  <p>Python</p>
                  <p>NumPy</p>
                  <p>Pandas</p>
                  <p>Matplotlib</p>
                  <p>Tensorflow</p>
                </div>
                <hr></hr>
              </div>
            </div>
          </div>

          {/*first half end--*/}
        </div>
      </div>
    </section>
  );
};

export default Skills;
