import React from "react";
import ProfileData from "../ProfileData.json";

const About = () => {
  return (
    <section id="about" className="about">
      <div className="container">
        <div className="section-title">
          <h2>About Me</h2>
        </div>

        <div className="row">
          <div className="col-lg-10 pt-4 pt-lg-0 content" data-aos="fade-left">
            <h3>{ProfileData.AboutMe.Header}</h3>
            <p className="fst-italic">{ProfileData.AboutMe.subHeading}</p>
            <p>{ProfileData.AboutMe.body}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
