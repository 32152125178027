import "./App.css";
import React, { useEffect } from "react";
import AOS from "aos";
import "../node_modules/aos/dist/aos.css";

import NavBar from "./components/NavBar";
import MainHero from "./components/MainHero";
import About from "./components/About";
import Skills from "./components/Skills";
import ProjectHero from "./components/ProjectHero";
import Contact from "./components/Contact";
import Resume from "./components/Resume";

let on = null;

function App() {

  const select = (el, all = false) => {
    el = el.trim();
    if (all) {
      return [...document.querySelectorAll(el)];
    } else {
      return document.querySelector(el);
    }
  };

  //event listner
  on = (type, el, listener, all = false) => {
    let selectEl = select(el, all);
    if (selectEl) {
      if (all) {
        selectEl.forEach((e) => e.addEventListener(type, listener));
      } else {
        selectEl.addEventListener(type, listener);
      }
    }
  };

  useEffect(() => {
    //navbar

    const navbarlinksActive = () => {
      let navbarlinks = select("#navbar .scrollto", true);
      let position = window.scrollY + 200;
      navbarlinks.forEach((navbarlink) => {
        if (!navbarlink.hash) return;
        let section = select(navbarlink.hash);
        if (!section) return;
        if (
          position >= section.offsetTop &&
          position <= section.offsetTop + section.offsetHeight
        ) {
          navbarlink.classList.add("active");
        } else {
          navbarlink.classList.remove("active");
        }
      });
    };
    //onscroll
    const onscroll = (el, listener) => {
      el.addEventListener("scroll", listener);
    };
    //window listner
    window.addEventListener("load", navbarlinksActive);
    onscroll(document, navbarlinksActive);
    //AOS
    AOS.init({ duration: 1500 });
    AOS.refresh();
    //AOS

    //onclick
    on("click", ".mobile-nav-toggle", function (e) {
      select("body").classList.toggle("mobile-nav-active");
      this.classList.toggle("bi-list");
      this.classList.toggle("bi-x");
    });
    //Scrool with ofset on links with a class name .scrollto
    on(
      "click",
      ".scrollto",
      function (e) {
        if (select(this.hash)) {
          e.preventDefault();

          let body = select("body");
          if (body.classList.contains("mobile-nav-active")) {
            body.classList.remove("mobile-nav-active");
            let navbarToggle = select(".mobile-nav-toggle");
            navbarToggle.classList.toggle("bi-list");
            navbarToggle.classList.toggle("bi-x");
          }
          scrollto(this.hash);
        }
      },
      true
    );
    //Scroll
    const scrollto = (el) => {
      let elementPos = select(el).offsetTop;
      window.scrollTo({
        top: elementPos,
        behavior: "smooth",
      });
    };
    //back to the top
    let backtotop = select(".back-to-top");
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add("active");
        } else {
          backtotop.classList.remove("active");
        }
      };
      window.addEventListener("load", toggleBacktotop);
      onscroll(document, toggleBacktotop);
    }
    //Scroll with ofset on page load with hash links in the url
    window.addEventListener("load", () => {
      if (window.location.hash) {
        if (select(window.location.hash)) {
          scrollto(window.location.hash);
        }
      }
    });

    // Loop
  }, []);

  return (
    <>
      <NavBar />
      <i className="bi bi-list mobile-nav-toggle d-xl-none"></i>
      <MainHero />
      <main id="main">
        <About />
        <Skills />
        <Resume />
        <ProjectHero />
        <Contact />
      </main>

      <footer id="footer">
        <div className="credits">
          Made With ❤ <br />
          Powered by React
        </div>
      </footer>
      <a
        href="#top"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
}

export default App;
